import Vue from 'vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faHouse, faBarcode, faTriangleExclamation, faMagnifyingGlass, faPlus, faMinus, faBars, faAngleRight, faArrowLeft, faDeleteLeft,faPercent,
    faCartShopping, faPaperPlane, faClose, faCashRegister, faBoxes, faLock, faBasketShopping, faBoxesStacked,faAddressCard,
    faReceipt, faGear, faXmark, faChevronRight, faArrowRightFromBracket, faCreditCard, faMoneyBill, faChevronUp,faCrown,
    faArrowsRotate, faLayerGroup, faPenToSquare, faUsers, faUserPlus, faHandHoldingDollar, faCoins, faArrowRotateLeft,
    faGears, faMoneyBills, faArrowTrendDown, faPrint, faTrash,faTable,faSave,faChartLine,faUpload,faFolderTree,faCancel,faRotateLeft,faPhone,
    faCommentsDollar,faArrowsSplitUpAndLeft,faQuestionCircle,faChartSimple,faHandHoldingDroplet,faEnvelope,faExchange,faImage,faTrophy,
    faArrowRight , faExclamationCircle,faBell,faBusinessTime ,faBowlFood,faCalendarCheck,faEye,faClipboardList,faChevronDown,faPencil,faComments,faEllipsis,faFile

} from '@fortawesome/free-solid-svg-icons'

Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(
    faHouse, faBarcode, faTriangleExclamation, faMagnifyingGlass, faClose, faPlus, faMinus, faBars, faAngleRight, faArrowLeft,faCrown,faFile,faPhone,faTrophy,
    faDeleteLeft, faCartShopping, faPaperPlane, faCashRegister, faBoxes, faLock, faBasketShopping, faBoxesStacked,faImage,faRotateLeft,
    faReceipt, faGear, faArrowRightFromBracket, faXmark, faChevronRight, faCreditCard, faMoneyBill, faChevronUp,faArrowRight,
    faArrowsRotate, faLayerGroup, faPenToSquare, faUsers, faHandHoldingDollar, faCoins, faUserPlus, faArrowRotateLeft,
    faGears, faMoneyBills, faArrowTrendDown, faPrint, faTrash,faTable,faSave, faArrowsRotate, faLayerGroup,faPercent,
    faPenToSquare, faUsers, faHandHoldingDollar, faCoins, faUserPlus,faChartLine,faUpload,faFolderTree,faCommentsDollar,
    faArrowsSplitUpAndLeft,faQuestionCircle,faChartSimple, faHandHoldingDroplet,faEnvelope,faExchange,faCancel,faAddressCard,
    faExclamationCircle , faBell ,faBusinessTime, faBowlFood,faCalendarCheck,faEye,faClipboardList,faChevronDown,faPencil,faComments,faEllipsis
)

