import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'bootstrap/dist/js/bootstrap.min'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fonts/icons.js'
import DrawerLayout from 'vue-drawer-layout'
import './services/GlobalVariables'
import './services/ProxyHandler'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import VueToggles from 'vue-toggles';
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css";
import OneSignalVue from 'onesignal-vue'

Vue.use(OneSignalVue);

import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use(BootstrapVue);

import VueQriously from 'vue-qriously'

Vue.use(VueQriously)


// Import stylesheet
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueBarcodeScanner from 'vue-barcode-scanner'
let options = {
    sensitivity: 100, // default is 100
    requiredAttr: true, // default is false
    controlSequenceKeys: ['NumLock', 'Clear'], // default is null
    callbackAfterTimeout: true // default is false
}

Vue.use(VueBarcodeScanner, options);
Vue.component('loading', Loading);
Vue.component('VueToggles', VueToggles);
Vue.component('v-select',vSelect)
Vue.use(VueVirtualScroller)
Vue.use(DrawerLayout);
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),

}).$mount('#app')
