import axios from "axios"
import {Storage} from "@/services/Storage";
import router from "@/router";

export default {
    name: 'payment',
    actions: {
        createTransaction(context,payload){
            return axios.post(gateWayApi+`CreateTransaction`,payload)
                .then(({data}) => {
                    if (data.Error) throw new Error(data)
                    else window.open(`https://gatewaykazang.chrilantech.com/kazangOnline?id=${data.ID}`, `_self`)
                    // else window.open(`http://192.168.30.10:8081/kazangOnline?id=${data.ID}`, `_self`)
                }).catch(err=>errorHandler.customerKazangError(err))
        },

        createdSandBoxTransaction : (context , payload) => {
           return axios.post(gateWayApi+`CreateTransaction`,payload)
               .then(({data}) => {
                   if (data.Error) throw new Error(data)
                   else window.open(`https://sandboxkazang.abc.co.zm/kazangOnline?id=${data.ID}`, `_self`)
                   // else window.open(`http://192.168.0.161:8080/kazangOnline?id=${data.ID}`, `_self`)
               }).catch(err=>errorHandler.customerKazangError(err))
        },



        getDataByRefID(context,id){
            return axios.get(gateWayApi+`TransactionDetails?id=`+id)
                .then(({data}) => data)
                .catch(err=>errorHandler.customerKazangError(err,true))
        },

        getImage(context,id){
            return axios.get(gateWayImage+`logo?apikey=`+id)
                .then(({data}) => data)
                .catch(err=>errorHandler.customerKazangError(err,true))
        },

        getSandBoxDataByRefID(context,id){
            return axios.get(gateWayApi+`TransactionDetails?id=`+id)
                .then(({data}) => data)
                .catch(err=>errorHandler.customerKazangError(err,true))
        },

        makeSandBoxKazangPayment(context,payload){
            return axios.post(gateWayApi+`ProcessSale`,payload,{timeout : 1000 * 60 * 2})
                .then(({data}) => window.open(data.PushUrl, '_self'))
                .catch(err=> errorHandler.customerKazangError(err, true))
        },

        makeKazangPayment(context,payload){
            return axios.post(gateWayApi+`ProcessSale`,payload,{timeout : 1000 * 60 * 2})
                .then(({data}) => window.open(data.PushUrl, '_self'))
                .catch(err=> errorHandler.customerKazangError(err, true))
        },
        upgradeToPremium: async () => {
            return Swal.fire({
                title: `<b class="text-white" style="font-family: 'Goudy Stout'; font-size: 13px">UPGRADE TO PREMIUM</b>`,
                background : `#3700b3`,
                html : `<span class="text-white">SORRY THIS FEATURE IS ONLY AVAILABLE IN PREMIUM</span>
                        <span class="text-white">ONLY K50 P/M</span>`,
                showCloseButton : true,
                confirmButtonText : `<b class="text-dark">FIND OUT MORE</b>`,
                confirmButtonColor : `white`,
            }).then(result=>{
                if (result.isConfirmed){
                    return router.push({ name : `premium-upgrade` });
                }
            });
        },
        proceedToUpgrade:async (context,payload)=>{
            let customerDetails = await Storage.getDefaultStore();
            let clientRef = generateUUID();
            let obj = {
                API_KEY: '945ac88d-e88a-11ed-91dd-00163e8d4996',
                amount: payload.amount,
                Service_Description: `Upgrade to Premium Kazang POS`,
                Client_Refrence: clientRef,
                Cancel_Url: 'https://gatewaykazang.chrilantech.com/test-failed',
                // Sucess_Url: `http://192.168.30.10:8081/upgradeSuccess/${customerDetails.id}/${payload.numberOfDays}`,
                Sucess_Url: `https://zero.chrilantech.com/upgradeSuccess/${customerDetails.id}/${payload.numberOfDays}`,
            }
            return context.dispatch(`createTransaction`,obj)
        },
        calculatePaymentAmount:()=> {
            return axios.get(franchiseTomcatApi + `stores/calculatePaymentAmount`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
    }
}
