<template>
  <full-page title="Training Videos">
    <div class="page text-center justify-content-center">
      <div class="accordion-header mt-2 mx-2" >
        <h1>Scan the code</h1>
      </div>
      <!--    <img class="scan-img" src="../../../assets/img/MobileApp.jpg" alt="Mobile App">-->
      <img src="../../../assets/img/framek.png" alt="">
      <!--    <qriously value="https://www.youtube.com/watch?v=nIg1YICU7oI&list=PLTQrSpSFLPjSWI6NwFk3ASrtjUiNZ4fqz"-->
      <!--              :size="400">-->
      <!--    </qriously>-->
    </div>
  </full-page>

</template>

<script>
import {Storage} from "@/services/Storage";
import FullPage from "@/views/point-of-sale/tables/FullPage.vue";
export default {
  name: "ReportsView",
  components: {FullPage},
  data(){
    return {
      storeOTP : null,
    }
  },
  methods : {

  }
}
</script>

<style lang="scss" scoped>
.page{
  max-height: 100vh;
  overflow-y: scroll;
  max-width: 100%;
  overflow-x: hidden;
}
.scan-img {
  object-fit: contain;
  width: 90%;
  max-width: 500px;
  //height: 500px;
}
</style>