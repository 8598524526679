<template>
  <div>
    <loading :active.sync="loading"
             :can-cancel="false"
             :is-full-page="true"></loading>

    <div class="method-sheet">
      <div class="header">
        <h4><b> Select Payment Method </b></h4>
        <h6><b> Sale Total : {{ $utils.formatMoney(saleTotal - discount.total) }} </b></h6>
      </div>

      <div class="payment-method-btn">
        <button class="lyt-soft-btn" @click="processTransaction('Cash')">
          <font-awesome-icon icon="fa-solid fa-money-bills"/>
          Cash
        </button>
      </div>

      <div class="payment-method-btn">
        <button class="lyt-soft-btn" @click="processTransaction('Card')">
          <font-awesome-icon icon="fa-solid fa-credit-card"/>
          Card
        </button>
      </div>

      <div class="payment-method-btn" v-if="customer">
        <button class="lyt-soft-btn" @click="processTransaction('AccountSale')">
          <font-awesome-icon icon="fa-solid fa-address-card"/>
          Account
        </button>
      </div>

      <div class="payment-method-btn" v-if="!saleDetails.refund">
        <button class="lyt-soft-btn" @click="kazangPayment">
          <img height="25px" src="@/assets/img/Kazang.png" alt="Kazang">
          Kazang
        </button>
      </div>

<!--      <div class="payment-method-btn" v-if="!saleDetails.refund">-->
<!--        <button class="lyt-soft-btn" @click="spennPayment">-->
<!--          <img height="35px" src="@/assets/img/SpennLogo.png" alt="Spenn">-->
<!--          Spenn-->
<!--        </button>-->
<!--      </div>-->

      <div class="payment-method-btn">
        <button class="lyt-soft-btn" @click="goToSplitScreen">
          <font-awesome-icon icon="fa-solid fa-arrows-split-up-and-left"/>
          Split
        </button>
      </div>

      <div class="payment-method-btn" v-for="method in paymentMethods">
        <button class="lyt-soft-btn" @click="processTransaction(method.name)">
          {{ method.name }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentMethods",
  props: {
    setSwipe: {type: Function, required: true}
  },
  computed: {
    saleDetails() {
      return this.$store.getters.getSaleDetails
    },
    customer() {
      return this.$store.getters.getCustomer;
    },
    saleTotal() {
      return this.$store.getters.getSaleTotal + this.$store.getters.getTipAmount
    },
    discount() {
      return this.$store.getters.getSaleDiscount;
    },
    paymentMethods() {
      return this.$store.getters.getPaymentMethods
          .filter(method => method.active);
    }
  },
  data() {
    return {
      loading: false,
      showCloseButton : false,
    }
  },
  methods: {
    processTransaction(type) {
      this.setSwipe();
      this.$utils.showLoading();
      let obj = this.saleDetails;
      obj.type = type;
      return this.$store.dispatch("processTransaction", obj)
          .then((res) => {
            if (res) {
              return this.$router.push({name: 'viewPayment'})
            }
          }).finally(() => {
            this.$utils.hideLoading()
            this.setSwipe();
          });
    },

    async updateCloseButton(){
      this.showCloseButton = false;
      await new Promise(resolve => setTimeout(resolve, 6000));
      this.showCloseButton = true;
    },

    goToSplitScreen() {
      let obj = this.saleDetails;
      obj.type = 'Split';
      this.$store.commit('setSaleDetails', obj);
      return this.$router.push({name: 'split-payment'})
    },

    kazangPayment() {
      return Swal.fire({
        title: 'Customer Phone No. ?',
        icon: 'question',
        input: 'number',
        showCancelButton: true,
        confirmButtonText: 'Process',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: (input) => {
          if (!input) return Swal.showValidationMessage(`Customer Phone No.`);
          return this.$store.dispatch('firstKazangPaymentStep', input)
              .then((res) => {
                if (res.Error) throw new Error(res.Error)
                else return res;
              }).catch(err => Swal.showValidationMessage(err))
        },
      }).then((result) => {
        if (result.isConfirmed) {
          return this.$router.push({name: 'viewPayment'});
        }
      })
    },

    async confirmKazangPayment(firstKazangStepResponse) {
      console.log(firstKazangStepResponse)
      if (firstKazangStepResponse === "processed") {
        return this.$router.push({name: 'viewPayment'})
      }
      return Swal.fire({
        title: 'Confirm Kazang Payment',
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: 'Process',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return this.$store.dispatch('secondKazangStep', firstKazangStepResponse)
              .then((res) => {
                if (res.Error) throw new Error(res.Error)
              })
              .catch(err => Swal.showValidationMessage(err))
        },
      }).then((result) => {
        if (result.isConfirmed) {
          return this.$router.push({name: 'viewPayment'})
        }
      })
    },

    spennPayment() {
      return Swal.fire({
        title: 'Customer Phone No. ?',
        icon: 'question',
        input: 'number',
        showCancelButton: true,
        confirmButtonText: 'Process',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: (input) => {
          if (!input) return Swal.showValidationMessage(`Customer Phone No.`);
          return this.$store.dispatch('firstSpennPaymentStep', input)
              .then((res) => {
                if (res.Error) throw new Error(res.Error)
                else return res;
              }).catch(err => Swal.showValidationMessage(err))
        },
      }).then((result) => {
        if (result.isConfirmed) {
          return this.confirmSpennPayment(result.value)
        }
      })
    },

    confirmSpennPayment(firstSpennStepResponse) {
      if (firstSpennStepResponse === "processed") {
        return this.$router.push({name: 'viewPayment'})
      }
      return Swal.fire({
        title: 'Confirm Spenn Payment',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Process',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return this.$store.dispatch('secondSpennStep', firstSpennStepResponse)
              .then((res) => {
                if (res.Error) throw new Error(res.Error)
              })
              .catch(err => Swal.showValidationMessage(err))
        },
      }).then((result) => {
        if (result.isConfirmed) {
          return this.$router.push({name: 'viewPayment'})
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.method-sheet {
  padding: 15px;
}

h4, h6 {
  display: flex;
  justify-content: center;
}

button {
  color: white;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  border-radius: 8px;
  height: 90%;
  width: 75%;
  max-width: 600px;
}

.payment-method-btn {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
  height: 60px;
}

.header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  padding: 10px;
}
</style>