<template>
  <div id="app">
    <div>
      <NetworkStatus/>
    </div>
    <Loader/>
    <router-view/>
    <custom-modal ref="update" title="Please Update" size="fullscreen">
<!--      <div class="box-container mt-2">-->
<!--        <div class="box red-background-blink">-->
<!--          <h6 class="route-btn w-100 bg-transparent text-white"> <h3 class="card-title">You have <strong>{{ days }} days {{ hours }} hours {{ minutes }} minutes {{ seconds }} seconds</strong> before you get logged out.</h3> </h6>-->
<!--        </div>-->
<!--      </div>-->
      <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div
              v-for="(step, index) in steps"
              :key="index"
              class="carousel-item"
              :class="{ active: index === 0 }"
          >
            <img :src="step.image" class="img d-block w-100" :alt="'Slide ' + (index + 1)"/>
            <div class="carousel-caption d-none d-md-block"></div>
          </div>
        </div>
        <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleSlidesOnly"
            data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" style="background-color: #1c1c1c" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleSlidesOnly"
            data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" style="background-color: #1c1c1c" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </custom-modal>
  </div>
</template>

<script>
import {EventBus} from "@/utils/event-bus";
import Loader from "@/components/layout/Loader";
import NetworkStatus from "@/components/layout/NetworkStatus.vue";
import CustomModal from "@/components/layout/utils/CustomModal.vue";

export default {
  components: {CustomModal, NetworkStatus, Loader},
  beforeMount() {
    if (!routeExceptions.includes(this.name)) {
      this.$store.dispatch('syncData');
    }
  },
  computed: {
    loading() {
      return this.$store.getters.getLoadingState;
    },
    isSmartInvoiced() {
      return this.$store.getters.getSmartInvoicingStatus;
    },
    curUser() {
      return this.$store.getters.getCurrentUser
    },
  },
  mounted() {
    this.updateApp();
  },
  data() {
    return {
      timeLeft: 0, // Total time left in milliseconds
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      timer: null,
      name: this.$route.name,
      steps: [
        {image: require('@/assets/img/update/1st.png')},
        {image: require('@/assets/img/update/step2.png')},
        {image: require('@/assets/img/update/step3.png')},
        {image: require('@/assets/img/update/step4.png')},
        {image: require('@/assets/img/update/step5.png')},
        {image: require('@/assets/img/update/step6.png')},
        {image: require('@/assets/img/update/step7.png')},
        {image: require('@/assets/img/update/step8.png')},
        {image: require('@/assets/img/update/last.png')}
      ]
    };
  },
  methods: {
    updateCountdown() {
      // Get current date
      const now = new Date();

      // Get next Sunday (the next 00:00:00 on Sunday)
      const nextSunday = new Date(now);
      nextSunday.setDate(now.getDate() + (8 - now.getDay())); // Set to next Sunday
      nextSunday.setHours(0, 0, 0, 0); // Set time to midnight

      // Calculate the time difference
      this.timeLeft = nextSunday - now;

      // If timeLeft is less than or equal to 0, stop the countdown
      if (this.timeLeft <= 0) {
        clearInterval(this.timer); // Stop the countdown
        return;
      }

      // Calculate days, hours, minutes, and seconds
      this.days = Math.floor(this.timeLeft / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((this.timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((this.timeLeft % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((this.timeLeft % (1000 * 60)) / 1000);
    },

    startCountdown() {
      this.updateCountdown();

      this.timer = setInterval(() => {
        this.updateCountdown();
      }, 1000);
    },

    async updateApp() {
      return this.$store.dispatch("checkSmartInvoicing")
          .then(async () => {
            if (this.isSmartInvoiced) {
              let showPopup = false;
              try {
                let isSmartInvoice = Printing.smartInvoice();
                showPopup = !!isSmartInvoice;
              } catch (e) {
                // showPopup = true
              }
              // if (showPopup) {
              //   this.$refs.update.openModal();
              //   // let user = await this.curUser;
              //   // if (user) {
              //   //   if (user.Username === 'Admin') {
              //   //     user = localStorage.getItem("supportAgent");
              //   //     localStorage.removeItem(`supportAgent`);
              //   //   }
              //   //   await this.$store.dispatch(`recordLogout`, user.Username)
              //   //   await this.$router.push({name: 'poslogin'})
              //   // }
              // }
            }
          });
    }
  },
  created() {
    EventBus.$on('user-logged-in', this.updateApp);
    this.startCountdown();
  },
  beforeDestroy() {
    EventBus.$off('user-logged-in', this.updateApp);
    clearInterval(this.timer);
  }
};
</script>

<style lang="scss">
@import "assets/css/styles";

.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-inner {
  width: 100%;
}

.carousel-caption {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  text-align: left;
}

.img {
  height: 50vh;
  object-fit: contain;
}

.box-container {
  display: flex;
  justify-content: center; /* Center contents horizontally */
  margin-bottom: 10px; /* Adjust as needed */
}

.red-background-blink {
  background-color: red;
  animation: blink 1s infinite;
  padding: 10px 20px; /* Adjust height and width */
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
</style>
